import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/src/ui/components/Carousel/Carousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/ui/components/cart/cart.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Heading"] */ "/app/src/ui/components/Heading/Heading.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/ui/components/icon/icon.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/ui/components/Link/Link.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/ui/components/MainNavigation/components/HeaderWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/ui/components/MainNavigation/MainNavigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/ui/components/navigation/page/PageHeaderLogo.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/ui/components/Page404/Page404.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/ui/components/ProductCard/ProductCardBadges.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/ui/components/ProductCard/ProductCardRoot.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/ui/components/search/search.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/ui/components/Topbar/components/CustomerServicePopup.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/utils/imageLoader.ts");
